<template>
  <v-text-field
    type="text"
    outlined
    placeholder="Ingrese importe"
    style="font-size: 1.3rem;"
    v-model="displayValue"
    @input="formatInput"
    @blur="finalizeFormatting"
    @keydown="restrictInput"
    hide-details
  ></v-text-field>
</template>

<script>
export default {
  data() {
    return {
      displayValue: "",
      rawValue: "",
    };
  },
  methods: {
    formatInput() {
      // Eliminar cualquier carácter que no sea un número
      this.rawValue = this.displayValue.replace(/[^\d]/g, "");

      // Convertir la cadena a un número y luego a una cadena con formato
      if (this.rawValue) {
        const formatted = new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 2,
        }).format(this.rawValue / 100);

        this.displayValue = formatted;
      } else {
        this.displayValue = "";
      }
    },
    finalizeFormatting() {
      if (this.rawValue) {
        const formatted = new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 2,
        }).format(this.rawValue / 100);

        this.displayValue = formatted;
      }
      this.$emit("input-change", this.rawValue); // Emitir el valor sin formato
    },
    restrictInput(event) {
      // Permitir solo números y teclas de control
      const allowedKeys = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "Tab",
      ];
      if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}
</style>
