<template>
  <layout-page title="Generar pago QR">
    <v-stepper
      non-linear
      :elevation="0"
      class="max-w-[750px] mx-auto"
      v-model="step"
      style="font-family: 'Montserrat' !important;"
    >
      <v-stepper-header>
        <v-stepper-step step="1">
          Ingresar datos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Escanear QR
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Descargar recibo
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="w-full mx-auto md:w-[600px] my-16" ref="onestep">
            <p class="mb-2 text-start mx-1">Importe (*)</p>
            <FormattedInput @input-change="(i) => (importe = i)" />
            <p class="mt-2 text-start text-red-500 mx-1" v-if="errors.importe">
              {{ errors.importe }}
            </p>

            <v-checkbox class="mt-10" v-model="agregarCorreo" hide-details>
              <template v-slot:label>
                <span class="mt-3">Agregar correo electrónico</span>
              </template>
            </v-checkbox>

            <div v-if="agregarCorreo">
              <p class="my-2 text-start mx-1">Correo electrónico</p>
              <v-text-field
                type="email"
                outlined
                placeholder="ejemplo@ejemplo.com"
                style="font-size: 1.15rem;"
                v-model="email"
                hide-details
              ></v-text-field>
            </div>
            <p class="mt-2 text-start text-red-500 mx-1" v-if="errors.email">
              {{ errors.email }}
            </p>

            <v-checkbox v-model="agregarReferencia" hide-details class="mt-0">
              <template v-slot:label>
                <span class="mt-3">Agregar referencia</span>
              </template>
            </v-checkbox>

            <div v-if="agregarReferencia">
              <p class="my-2 text-start mx-1">Referencia</p>
              <v-text-field
                type="text"
                outlined
                placeholder="Ingrese referencia de pago"
                style="font-size: 1.15rem;"
                v-model="referencia"
                hide-details
              ></v-text-field>
            </div>
            <p
              class="mt-2 text-start text-red-500 mx-1"
              v-if="errors.referencia"
            >
              {{ errors.referencia }}
            </p>

            <v-checkbox v-model="agregarVencimiento" hide-details class="mt-0">
              <template v-slot:label>
                <span class="mt-3">Seleccionar fecha de vencimiento</span>
              </template>
            </v-checkbox>

            <div v-if="agregarVencimiento">
              <p class="my-2 text-start mx-1">Fecha de vencimiento</p>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :value="formatDate(vencimiento)"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vencimiento"
                  no-title
                  @input="menu = false"
                  locale="es-ES"
                  :min="moment().format('yyyy-MM-DD')"
                  color="red"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <!-- <div class="qr-container mt-6">
            <div></div>

            <qrcode-vue :value="resultado.url_qr" :size="190"></qrcode-vue>
          </div>
          <p class="text-lg mt-3">{{ formatTime(timeLeft) }} s</p> -->

          <v-dialog
            v-model="dialog"
            persistent
            max-width="600"
            class="container-fluid"
            v-if="step == 2"
          >
            <v-card>
              <v-card-text class="pb-2 pt-6">
                Escaneá y pagá con QR
              </v-card-text>

              <v-card-title class="text-h6 justify-center p-0">
                Importe {{ formatImport(importe) }}
              </v-card-title>

              <!-- Contenido QR -->
              <v-card-content>
                <div class="qr-container mt-4">
                  <div></div>

                  <div :style="timeLeft == 0 ? 'opacity: 0' : ''">
                    <qrcode-vue
                      :value="resultado.url_qr"
                      :size="190"
                    ></qrcode-vue>
                  </div>
                </div>
                <!-- <p
                    class="text-lg mt-3 font-medium"
                    :class="timeLeft == 0 ? 'text-danger' : ''"
                  >
                    {{
                      timeLeft > 0 ? `${formatTime(timeLeft)} s` : "QR Vencido"
                    }}
                  </p> -->

                <div class="my-6">
                  <p class="text-decoration-underline mb-2">Compartir por:</p>
                  <v-btn
                    :loading="loadingEmail"
                    @click="sendQrFactura()"
                    icon
                    class="red mr-4"
                    dark
                  >
                    <v-icon>
                      email
                    </v-icon>
                  </v-btn>

                  <v-btn
                    @click="sendQrFactura()"
                    icon
                    disabled
                    class="primary"
                    dark
                  >
                    <v-icon>
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                </div>

                <br />
                <v-progress-linear
                  :indeterminate="true"
                  color="green"
                  height="6"
                ></v-progress-linear>
              </v-card-content>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Botones -->
                <div class="flex space-x-20 my-4">
                  <!-- Botón de Volver -->
                  <div>
                    <v-btn
                      style="font-family: 'Montserrat' !important;"
                      class="font-weight-bold grow"
                      text
                      outlined
                      @click="step = 1"
                    >
                      Cancelar
                    </v-btn>
                  </div>
                  <!-- Botón de Regenerar 
                    <v-btn
                      class="primary font-weight-bold text-subtitle-1"
                      style="font-family: 'Montserrat' !important;"
                      :disabled="timeLeft != 0"
                      text
                      @click="generarCheckout()"
                      :loading="loading"
                    >
                      Regenerar
                    </v-btn> -->
                </div>
              </v-card-actions>

              <!--ANIMACION PAGO EXITOSO O FALLIDO-->
              <div
                class="animacion-pago"
                v-if="mostrarAnimacion"
                :class="
                  `bg-${resultado.exito ? '[#00BA00]' : '[#F44336]'}
              `
                "
              ></div>
            </v-card>
          </v-dialog>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="py-10">
            <img
              src="../../../public/assets/img/pago_exitoso.png"
              alt="Estado de pago"
              class="mx-auto my-8"
              width="130px"
              v-if="resultado.exito"
            />
            <img
              src="../../../public/assets/img/pago_cancelado.png"
              alt="Estado de pago"
              class="mx-auto my-8"
              width="130px"
              v-else
            />

            <p
              :class="
                `bg-${resultado.exito ? '[#00BA00]' : '[#F44336]'}
                `
              "
              class="py-3 font-bold text-white rounded-lg text-lg"
            >
              {{
                resultado.exito
                  ? "Tu pago fue procesado exitosamente"
                  : "No se pudo procesar tu pago"
              }}
            </p>

            <v-btn
              large
              style="font-family: 'Montserrat' !important;"
              class="mt-8 font-bold bg-slate-100"
              text
              outlined
              block
              @click="descargarPDF(resultado.pdf_url)"
              v-if="resultado.exito"
            >
              Descargar Recibo
              <v-icon class="ml-3">mdi-tray-arrow-down</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog
      persistent
      max-width="550"
      class="container-fluid"
      v-model="dialogEmail"
    >
      <v-card class="py-3">
        <v-card-title class="text-h6 justify-center p-0">
          Correo electrónico
        </v-card-title>

        <!-- Contenido QR -->
        <v-card-content>
          <div class="m-3">
            <p class="my-2 text-start mx-1">Correo electrónico</p>
            <v-text-field
              type="email"
              outlined
              placeholder="ejemplo@ejemplo.com"
              style="font-size: 1.15rem;"
              v-model="email"
              hide-details
            ></v-text-field>

            <p class="mt-2 text-start text-red-500 mx-1" v-if="errors.email">
              {{ errors.email }}
            </p>
          </div>
        </v-card-content>

        <v-card-actions>
          <v-btn
            style="font-family: 'Montserrat' !important;"
            class="primary font-weight-bold grow text-white"
            text
            outlined
            @click="sendQrFactura()"
            large
            :loading="loadingEmail"
          >
            enviar
          </v-btn>
        </v-card-actions>

        <!--ANIMACION PAGO EXITOSO O FALLIDO-->
        <div
          class="animacion-pago"
          v-if="mostrarAnimacion"
          :class="
            `bg-${resultado.exito ? '[#00BA00]' : '[#F44336]'}
              `
          "
        ></div>
      </v-card>
    </v-dialog>

    <div
      class="flex gap-10 items-center mx-auto mb-8"
      v-if="step != 3"
      style="max-width: 750px;"
    >
      <!-- Boton de Volver -->
      <div class="flex justify-end" v-if="step > 1">
        <v-btn
          large
          style="font-family: 'Montserrat' !important;"
          class="font-weight-bold grow"
          text
          outlined
          @click="step = 1"
        >
          Volver
        </v-btn>
      </div>
      <!-- Boton de Continuar justificado al final -->
      <div class="flex justify-end gap-4" v-if="step == 1">
        <v-btn
          class="primary font-weight-bold text-subtitle-1"
          text
          @click="generarCheckout()"
          :loading="loading"
          :disabled="!formValid"
          large
          style="font-family: 'Montserrat' !important;"
        >
          Generar QR
        </v-btn>
      </div>
    </div>
  </layout-page>
</template>

<script>
import moment from "moment";
import LayoutPage from "../../components/LayoutPage.vue";
import { mapGetters } from "vuex";
import checkoutService from "../../services/global/checkout";
import formatDate from "../../utils/formatDate";
import QrcodeVue from "qrcode.vue";
import formatImport, { formatImportFloat } from "../../utils/formatImport";
import FormattedInput from "./FormattedInput.vue";
import autoAnimate from "@formkit/auto-animate";
export default {
  components: { LayoutPage, QrcodeVue, FormattedInput },
  comments: { LayoutPage },
  name: "GenerarPagoQR",
  data() {
    return {
      formValid: true,
      valid: false,
      dialogEmail: false,

      importe: "",
      vencimiento: moment().format("yyyy-MM-DD"),
      referencia: null,
      resultado: {
        url_qr: "",
        exito: false,
        estado_pago: "",
        importe_pagado: "",
      },

      step: 1,

      agregarCorreo: false,
      agregarReferencia: false,
      agregarVencimiento: false,
      mostrarAnimacion: false,
      menu: false,
      email: "",

      loading: false,
      loadingEmail: false,
      timeLeft: 540, // Tiempo inicial en segundos
      timer: null,
      isRunning: false,
      errors: {
        importe: "",
        email: "",
        referencia: "",
      },

      dialog: true,

      formatDate,
      formatImportFloat,
      formatImport,
    };
  },

  computed: {
    ...mapGetters(["CLIENTE_CUENTA_DATA", "getCurrentAccountId"]),
  },
  mounted() {
    this.$connect(); // conectando con websocket

    this.$options.sockets.onmessage = (data) => {
      const response = JSON.parse(data.data);

      const uuid = this.resultado.uuid;

      if (uuid === response.data.uuid) {
        this.mostrarAnimacion = true;
        this.stopTimer();

        setTimeout(() => {
          this.step = 3;
          this.resultado = response.data;
        }, 3000);
      }
    };

    autoAnimate(this.$refs.onestep);
  },

  destroyed() {
    this.$disconnect(); // desconectando websocket
  },

  methods: {
    async generarCheckout() {
      const { apikey, tipos_pago } = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id === this.getCurrentAccountId
      );

      const timestamp = moment()
        .unix()
        .toString();

      if (!this.agregarCorreo) this.email = "";
      if (!this.agregarReferencia) this.referencia = null;

      if (this.isValidForm()) {
        let body = {
          payer_name: this.CLIENTE_CUENTA_DATA.cliente,
          description: "QR GENERADO DESDE BACKOFICCE",
          first_total: Number(this.importe),
          first_due_date: moment(this.vencimiento)
            .add(1, "days")
            .format("DD-MM-YYYY"),
          external_reference: this.referencia ?? timestamp,
          payer_email: this.email ? this.email : "",
          payment_type: tipos_pago[0].PagoTipo,
          card_brand: "QR",
          channel: "QR",
          installments: "1",
          notificacion_url: "https://wee.ar",
          items: [
            {
              quantity: 1,
              description: "QR GENERADO DESDE BACKOFICCE",
              amount: Number(this.importe),
              identifier: timestamp,
            },
          ],
        };

        try {
          this.loading = true;
          const { data } = await checkoutService.generarQRFactura(
            [body],
            window.atob(apikey)
          );

          if (!data[0].url_qr) {
            return this.$toastr.e(
              "No se pudo crear el qr, inténto nuevamente."
            );
          }

          this.resultado = data[0];
          this.step = 2;
          this.timeLeft = 540;
          // this.startTimer();

          //this.$toastr.s("Pago generado con éxito");
        } catch (e) {
          this.$toastr.e("Servicio de pagos, momentáneamente no disponible");
          // console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    startTimer() {
      if (!this.isRunning) {
        this.isRunning = true;
        this.timer = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            this.stopTimer();
            // alert("¡El tiempo ha terminado!");
          }
        }, 1000);
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.isRunning = false;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    },

    isValidForm() {
      if (!this.importe) {
        this.errors.importe = "El importe es requerido";
        return false;
      }

      if (Number(this.importe) < 1500) {
        this.errors.importe = "El importe debe ser mayor a $15,00";
        return false;
      }

      if (this.agregarCorreo) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!this.email) {
          this.errors.email = "El correo es requerido";
          return false;
        }

        if (!emailRegex.test(this.email)) {
          this.errors.email = "El correo es inválido";
          return false;
        }
      }

      if (this.agregarReferencia) {
        if (!this.referencia) {
          this.errors.referencia = "La referencia de pago es requerida";
          return false;
        }
      }

      this.errors.importe = "";
      this.errors.email = "";
      this.errors.referencia = "";
      return true;
    },

    async sendQrFactura() {
      const { apikey } = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id === this.getCurrentAccountId
      );

      if (!this.email) {
        this.dialogEmail = true;
        this.agregarCorreo = true;
        this.isValidForm();
        return;
      }

      // console.log(this.email);

      if (!this.isValidForm()) {
        return;
      }

      try {
        this.loadingEmail = true;
        this.dialogEmail = false;
        await checkoutService.sendQRFactura(
          this.resultado,
          window.atob(apikey)
        );

        this.loadingEmail = false;
        this.step = 1;

        this.$toastr.s("QR enviado con éxito");
      } catch (error) {
        // console.error(error);
        this.loadingEmail = false;
        this.$toastr.e("Error al enviar el QR por correo.");
      }
    },

    enviarWhatsap() {
      window.open("https://wa.me/?text=" + this.urlPago, "_blank");
    },
    enviarEmail() {
      const message = "mailto:?subject=Pagos&body=" + this.urlPago;
      window.open(message, "_blank");
    },

    copiarPortapapeles() {
      // Se necesita hacer esto para acceder dentro de la funcion a la data del component

      navigator.clipboard.writeText(this.urlPago).then(
        () => this.$toastr.s("Enlace copiado con éxito."),
        () => this.$toastr.e("No se pudo copiar el enlace.")
      );
    },

    descargarPDF(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.textfield ::v-deep.v-messages__message {
  color: #666 !important;
}

.text-field-mask {
  border-bottom: 1px solid #777;
  min-height: 38px;
}
.qr-container {
  position: relative;
  min-width: 150px;
  min-height: 150px;
  width: 220px;
  height: 220px;
  transition: transform 0.6s ease-out;
  transform: scale(0.95);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-container:before,
.qr-container:after,
.qr-container > :first-child:before,
.qr-container > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: #c2d500;
  /* border-color: #000; */
  border-style: solid;
  content: " ";
}

/* top left corner */
.qr-container:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

/* top right corner */
.qr-container:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}

/* bottom right corner */
.qr-container > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

/* bottom left corner */
.qr-container > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}

.container-loading {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cccccc20;
}

.animacion-pago {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;

  animation: animacion 3s;
}

@keyframes animacion {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(570);
  }
}
.v-stepper__header {
  box-shadow: none;
}
</style>
